import './Table.css'

import * as React from 'react'
import { useMsal } from "@azure/msal-react";
import { downloadJobOutput } from '../utils/api';

import {
  Column,
  ColumnFiltersState,
  Table,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  getFacetedUniqueValues,
  SortingState,
} from '@tanstack/react-table'

type Job = {
  artifact_filename: string,
  id: string,
  script: string,
  status: string,
  created_on: string,
}

interface TableProps {
  jobs: Job[]
  setStatusMsg: React.Dispatch<React.SetStateAction<string>>
}

export function JobTable(props: React.PropsWithChildren<TableProps>) {
  const { jobs, setStatusMsg } = props


  const [sorting, setSorting] = React.useState<SortingState>([])
  const [downloadedJobs, setDownloadedJobs] = React.useState<string[]>([])

  // MSAL Hooks
  const { accounts, instance } = useMsal();


  // Cell Definititions
  const downloadCell = (row) => {
    const owl_id = row.id
    const fileName = row.artifact_filename

    if (row.status === 'Finished' && row.status !== 'Downloaded' && !downloadedJobs.includes(row.id)) {
      return (
        <a href="javascript:void(0);" onClick={() => {downloadJobOutput(owl_id, fileName); setDownloadedJobs([...downloadedJobs, row.id])}}>
          Download
        </a>
      );
    }
    else {
      return (
        <></>
      );
    }
  }

  // Column Definitions
  const columnHelper = createColumnHelper<Job>()

  const columns = [
    columnHelper.accessor('artifact_filename', {
        header: () => <span>Name</span>,
        cell: cell => <>{cell.getValue()}</>,
        enableSorting: false,
    }),
    columnHelper.accessor('id', {
        header: () => <span>ID</span>,
        cell: cell => <>{cell.getValue()}</>,
        enableSorting: false,
    }),
    columnHelper.accessor('script', {
        header: () => <span>Script</span>,
        cell: cell => <>{cell.getValue()}</>,
        enableSorting: false,
    }),
    columnHelper.accessor('status', {
        header: () => <span>Status</span>,
        cell: cell => <>{cell.getValue()}</>,
        enableSorting: false,
    }),
    columnHelper.accessor('created_on', {
        header: () => <span>Created On</span>,
        cell: cell => <>{cell.getValue()}</>,
        enableSorting: false,
    }),
    columnHelper.display({
      id: 'download',
      header: () => <span>Download</span>,
      cell: props => downloadCell(props.row.original),
    }),
  ]

  // Table Hook
  const table = useReactTable({
    data: jobs,
    columns,
    state: {
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })

  return (
    <table>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header=> (
              <th key={header.id}>
                  <div className='header-cell'>
                    <div
                      {...{
                        style: header.column.getCanSort()
                          ? {cursor: "pointer"}
                          : {},
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                    {{
                      asc: '🔼',
                      desc: '🔽',
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                
              </th>
            ))}
          </tr>
        ))}
      </thead>
      
      <tbody>
        {table.getRowModel().rows.map((row, index) => (
          <tr key={row.id} className={index % 2 == 0? 'even-row':'odd-row'}>
            {row.getVisibleCells().map(cell => (
              // try css: word-wrap: break-word; to get text to wrap properly
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      
    </table>
  )
}