import './Table.css'

import * as React from 'react'
import {Dropdown, Row, Container, Col, DropdownButton, ButtonGroup, SplitButton, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom';

import { callApiServer, downloadResource } from '../utils/api';

import {
  Column,
  ColumnFiltersState,
  Table,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  getFacetedUniqueValues,
  SortingState,
} from '@tanstack/react-table'

type Resource = {
  // Required Properties
  type: string
  name: string
  synopsis: string
  description: string
  tags: string[]
  catalogs: string[]

  // Documents only
  driveitem_id?: string
  file_extension: string
  owl_id?: string
  
  // Scripts only
  parameters?: {
    name: string
    description: string
    input_type: string
  }[]
  repo?: string
  repo_path?: string

  // Services only
  provider?: string
}

interface TableProps {
  resources: Resource[]
}

export function ResourceTable(props: React.PropsWithChildren<TableProps>) {

  const { resources } = props
  
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  const [sorting, setSorting] = React.useState<SortingState>([])
  

  // Custom Cell Renderers
  const NameCell = (cell) => {
    const url = `/resource/${cell.row.original.owl_id}`;
    return (
        <Link to={url} className='resource-link'>{cell.getValue()}</Link>
    )
  }

  const InfoCell = (cell) => {
    return (
      <span className='center-cell-content'>
        <OverlayTrigger
          delay={0}
          placement='left'
          overlay={
            <Tooltip>
              {cell.getValue()}
            </Tooltip>
          }
        >
          <img width='16' height='16' alt='tooltip-hover-icon' src='/ictooltip.png' id={`tooltip-icon-${cell.row.original.name}`} />
        </OverlayTrigger>
      </span>
    )
  }

  const TypeCell = (cell) => {
    const resourceType: string = cell.getValue()

    let iconFileName: string
    let altText: string

    if (resourceType === 'document') {
      const resourceFileExt: string = cell.row.original.file_extension
      switch(resourceFileExt) {
        case '.docx':
          iconFileName = '/icdocx.svg'
          altText = 'Word Document'
          break
        case '.xlsx':
          iconFileName = '/icxlsx.svg'
          altText = 'Excel Document'
          break
        case '.pptx':
          iconFileName = '/icpptx.svg'
          altText = 'Powerpoint Document'
          break
        case '.pdf':
          iconFileName = '/icpdf.svg'
          altText = 'PDF Document'
          break
        case '.csv':
          iconFileName = '/icxlsx.svg'
          altText = 'CSV File'
          break
        case '.mp4':
          iconFileName = '/icmp4.svg'
          altText = 'MP4 File'
          break
        default:
          iconFileName = '/icother.png'
          altText = 'Unknown Type'
          break
      }
    } else if (resourceType === 'script') {
      iconFileName = '/icscript.png'
      altText = 'script'
    } else if (resourceType === 'service') {
      iconFileName = '/icsvc.png'
      altText = 'service'
    } else {
      iconFileName = '/icother.png'
      altText = 'Unknown Type'
    }
    return (
      <span className='center-cell-content'>
        <img className='resource-icon' alt={altText} src={iconFileName} id={`icon_${cell.row.original.name}`} />
      </span>
    )
  }

  /* TODO: move the download function into downloadResource*/
  const DownloadCell = (row) => {
    const func = () => downloadResource(row.owl_id, row.type)

    return (
      <a href="javascript:void(0);" onClick={func}>
        Download
      </a>
    );
  }

  // Custom Sorting Functions
  const sortResourceTypes = (rowA, rowB, columnId) => {
    const typeA = rowA.original.type
    const typeB = rowB.original.type

    if (typeA === 'document' && typeA === typeB) {
      const extA = rowA.original.file_extension
      const extB = rowB.original.file_extension

      if (extA === extB) {
        return 0
      } else {
        return extA > extB ? 1 : -1
      }
    } else {
      return typeA > typeB ? 1 : -1
    }
  }

  // Table Columns
  const columnHelper = createColumnHelper<Resource>()

  const columns = [
    columnHelper.accessor('name', {
      header: () => <span>Name</span>,
      cell: NameCell,
      enableColumnFilter: false,
      enableSorting: true,
      sortingFn: "alphanumeric"
    }),

    columnHelper.accessor('synopsis', {
      header: () => <span>Info</span>,
      cell: InfoCell,
      enableColumnFilter: false,
      enableSorting: false,
    }),

    columnHelper.accessor('type', {
      header: () => <span>Type</span>,
      cell: TypeCell,
      enableColumnFilter: true,
      enableSorting: true,
      filterFn: "arrIncludesAll",
      sortingFn: sortResourceTypes,
    }),

    columnHelper.accessor('tags', {
      header: () => <span>Tags</span>,
      cell: cell => <>{cell.getValue().join(', ')}</>,
      enableColumnFilter: true,
      enableSorting: false,
      filterFn: "arrIncludesAll",
    }),

    columnHelper.display({
      id: 'download',
      header: () => <span>Download</span>,
      cell: props => DownloadCell(props.row.original),
      enableColumnFilter: false,
      enableSorting: false,
    }),
  ]

  // Table Hook
  const table = useReactTable({
    data: resources,
    columns,
    state: {
      columnFilters,
      sorting,
    },
    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })

  return (
    <table>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header=> (
              <th key={header.id}>
                  <div className='header-cell'>
                    <div
                      {...{
                        style: header.column.getCanSort()
                          ? {cursor: "pointer"}
                          : {},
                        onClick: header.column.getToggleSortingHandler(),
                      }}
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                    </div>
                    {header.column.getCanFilter() ? (
                        <Filter column={header.column} table={table} />
                    ) : null}
                    {{
                      asc: '🔼',
                      desc: '🔽',
                    }[header.column.getIsSorted() as string] ?? null}
                  </div>
                
              </th>
            ))}
          </tr>
        ))}
      </thead>
      
      <tbody>
        {table.getRowModel().rows.map((row, index) => (
          <tr key={row.id} className={index % 2 == 0? 'even-row':'odd-row'}>
            {row.getVisibleCells().map(cell => (
              // try css: word-wrap: break-word; to get text to wrap properly
              <td key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
      
    </table>
  )
}

function Filter({
  column,
  table,
}: {
  column: Column<any, unknown>
  table: Table<any>
}) {
  
  const sortedUniqueValues = Array.from(
    new Set(table
      .getPreFilteredRowModel()
      .flatRows.map(row => 
        row.getValue(column.id)
      )
      .flat())
  ).sort((a: string, b: string) => a.toLowerCase().localeCompare(b.toLowerCase()))

  const updateFilterList = (event) => {
    const value: string = event.target.value
    const checked: boolean = event.target.checked
    if (checked) {
      column.setFilterValue((old: string[]) => old === undefined? [value] : old.concat(value))
    } else {
      column.setFilterValue((old: string[]) => old.filter(val => val !== value))
    }
  }

  return (
    <Dropdown 
      id={column.id + 'list'}
      autoClose={'outside'} 
      drop='start'
    >
      <Dropdown.Toggle className='dropdown-toggle-img' as="img" src='icfilter.png' />
      <Dropdown.Menu as="form">
        {sortedUniqueValues.map((value: any) => (
          <Dropdown.Item as="label" key={value}>
            <input
              type="checkbox"
              value={value}
              onChange={updateFilterList}
            />
            {' ' + value}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
