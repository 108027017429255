import { msalInstance } from "../index";
import { loginRequest } from "../authConfig";

async function getAccessToken(instance, account) {
	const accessTokenRequest = {
		scopes: [`${import.meta.env.VITE_APP_CLIENT_ID}/.default`],
		account: account,
	};
	return await instance.acquireTokenSilent(accessTokenRequest);
}

export function downloadResource(owlId: string, resourceType: string) {
    // Make the cursor a progress spinner
    document.body.style.cursor = 'progress'
    let path;
    if (resourceType === 'document') {
        path=`/resources/${owlId}/download`
    } else if (resourceType === 'script') {
        path=`/download-script/${owlId}`
    } else {
        console.error("Unknown resource type")
        document.body.style.cursor = 'default'
        return
    }
    callApiServer(path, {method: 'GET'})
    .then(response => {
        // Extract filename from Content-Disposition header
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = 'default_filename.docx'; // A default filename if none is found
        if (contentDisposition) {
            const matches = contentDisposition.match(/filename\*?=['"]?(?:UTF-8'')?([^"';]+)/i);
            if (matches && matches.length > 1) {
                filename = decodeURIComponent(matches[1]);
            }
        }

        // Process the response as a Blob
        return response.blob().then(blob => {
            return { blob, filename };
        });
    })
    .then(({ blob, filename }) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        document.body.appendChild(a);
        a.style.display = 'none';
        a.href = url;
        a.download = filename; // Use the extracted filename
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a); // Clean up
        document.body.style.cursor = 'default';
    })
    .catch(error => {
        console.error("Error downloading file", error);
        document.body.style.cursor = 'default';
    });
}

export async function callApiServer(endpointPath: string, options: RequestInit): Promise<Response> {

    // Get the access token
	const account = msalInstance.getActiveAccount();
	if (!account) {
	  throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
	}

	const response = await msalInstance.acquireTokenSilent({
        scopes: [`${import.meta.env.VITE_APP_CLIENT_ID}/.default`],
        account: account
	});
	const accessToken = response.accessToken;
	const bearer = `Bearer ${accessToken}`;

    // Build Headers
    if (!options.headers) {
        options.headers = new Headers();
    } else if (!(options.headers instanceof Headers)) {
        options.headers = new Headers(options.headers);
    }
    options.headers.append("Authorization", bearer);

  

	const endpoint = `${import.meta.env.VITE_APP_DJANGO_SERVER_URL}${endpointPath}`;

    // Make the request
    return fetch(endpoint, options)
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response;
        })
        .catch(error => {
            console.error(error);
            throw error; // Rethrow the error to ensure the function doesn't return void
        });
}

export function downloadJobOutput(jobId: string, defaultFilename='output') {
    document.body.style.cursor = 'progress'

    // Call the API to download the job output for the given jobId
    callApiServer(`/jobs/${jobId}/download`, {method: 'GET'})
    .then(response => {
        const contentType = response.headers.get('Content-Type');

        // If the response is JSON, then the server has returned an error, display the error message
        if (contentType === 'application/json') {
            response.json().then(data => {
                console.log(data);
                document.body.style.cursor = 'default';
                alert(data.message);
            });
        } 
        // Else, then the server has returned the file, download the file
        else {
            const contentDisposition = response.headers.get('Content-Disposition');
            const filename = getFilenameFromContentDisposition(contentDisposition);
            response.blob().then(blob => {
                downloadBlob(blob, filename);
            });
        }
        document.body.style.cursor = 'default';
    })
    .catch(error => {
        console.error("Error downloading file", error);
        document.body.style.cursor = 'default';
    });
}

function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
}

function getFilenameFromContentDisposition(contentDisposition: string, defaultFilename: string ='output') {
    let filename = defaultFilename;
    if (contentDisposition) {
        const matches = contentDisposition.match(/filename\*?=['"]?(?:UTF-8'')?([^"';]+)/i);
        if (matches && matches.length > 1) {
            filename = decodeURIComponent(matches[1]);
        }
    }
    return filename;
}