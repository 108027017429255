import "./Jobs.css"

import * as React from "react";

import { JobTable } from "./ui_components/JobTable";
import { useMsal } from "@azure/msal-react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap"
import { callApiServer } from "./utils/api";


export default function Jobs(props) {
    const [jobs, setJobs] = React.useState([]);
    const [tableLoading, setTableLoading] = React.useState(true);
    const [refreshLoading, setRefreshLoading] = React.useState(false);
    const [statusMsg, setStatusMsg] = React.useState('');
 
    /* TODO: Replace this with the standard spinner component */
    const LoadingSpinner = () => (
        <div className="spinner">
          <div className="spinner-logo">
            <img src="/hybrid_pathways_links.png" alt="hybrid pathways logo" style={{ height: 45, padding: "2px" }} />
          </div>
        </div>
      );


    const updateTable = (refresh=false) => {
      if (refresh) {
        setRefreshLoading(true);
      }
      callApiServer("/jobs/", {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
      })
      .then(response => response.json())
      .then(data => {
        setJobs(data.jobs);
        setTableLoading(false);
        if (refresh) {
          setRefreshLoading(false);
        }
      })
    }

    React.useEffect(() => {
        updateTable();
    }, [])

    return (
        <>
          <h2>Workflow History (Beta)</h2>
          <div className="workflow-history-content" style={{ overflowY: "scroll", margin: "0.5em" }}>
            {tableLoading ? (
              <LoadingSpinner />
            ) : (
              <>
                <JobTable jobs={jobs} setStatusMsg={setStatusMsg} />
                <p>Note: You can only view the past seven (7) days jobs.</p>
              </>
            )}
          </div>

          <Container className="refresh-console" style={{flexWrap: "nowrap", padding: "2em", height: "10em"}}>
            <div style={tableLoading? {visibility: "hidden"} : {}}>
              <p>{statusMsg}</p>
              <Button variant={"secondary"} onClick={() => updateTable(true)}>
                Refresh
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="refresh-spinner"
                  style={refreshLoading ? {} : { color: "transparent", height: "20px", width: "20px" }}
                />
              </Button>
            </div>
          </Container>
      </>
    )
}